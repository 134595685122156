<template>
  <div class="container">
    <PublicHeader :totalName="$t('auth').low" />
    <div class="content">
      <van-field
        v-model="RealName"
        :label="`${$t('auth').userName}:`"
        :border="false"
        :placeholder="$t('auth').tips1"
        input-align="right"
      />
      <van-field
        v-model="IdCard"
        :label="`${$t('auth').IDNumber}:`"
        :border="false"
        :placeholder="$t('auth').tips2"
        input-align="right"
      />
      <div class="btn" @click="handleSub">{{ $t('auth').qrz }}</div>
    </div>
  </div>
</template>

<script>
import PublicHeader from '@/components_pc/publicHeaderNew'
import userApi from '@/api/user'
export default {
  components: {
    PublicHeader
  },
  data() {
    return {
      RealName: '',
      IdCard: ''
    }
  },
  methods: {
    async handleSub() {
      if (!this.RealName) {
        this.$toast(this.$t('auth').tips1)
        return false
      }
      if (this.IdCard === '') {
        this.$toast(this.$t('auth').tips2)
        return false
      }
      const form = {
        IdCard: this.IdCard,
        RealName: this.RealName
      }
      await userApi.IdentityAuthentication(form)
      this.$toast(this.$t('moneyAddr').success)
      setTimeout(() => {
        this.$router.go(-1)
      }, 1000)
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  padding-top: 45px;
  margin: 0 25%;
  .content {
    margin: 10px;
    padding: 10px;
    border-radius: 4px;
    background: #17181e;
    .van-field {
      background: transparent;
      & /deep/ .van-field__control,
      & /deep/ .van-field__label {
        color: #fff;
        font-size: 12px;
      }
    }
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 30px auto 0;
      height: 40px;
      border-radius: 18px;
      width: 100%;
      background: #e143ac;
      color: #fff;
      font-size: 14px;
    }
  }
}
</style>
